//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Footer',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      content: {},
      linkSections: {},
      descriptions: {},
      newsLetterEmail: '',
    }
  },
  async mounted() {
    this.content = (await this.$axios.get(`/contents/footer`)).data

    const vueObj = {}
    Object.assign(vueObj, this.content.details)
    this.linkSections = vueObj.Sections[0].links_section
    this.descriptions = vueObj.Sections[0].collection_descriptions

    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e)
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  },
  methods: {
    showDescrition() {
      return this.$route.path.split('/').pop() === 'home'
    },
    async submitNewsletter() {
      const emailIsValid = this.newsLetterEmail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (emailIsValid) {
        const captchaResult = await this.checkIfUserIsHuman('newsletter')
        if (!captchaResult.success || captchaResult.score < 0.5) {
          return
        }
        this.$toast.success('Newsletter signup sent')
        // TODO
      } else {
        this.$toast.error('Please enter a valid email address')
      }
    },
    async checkIfUserIsHuman(actionType) {
      let googleResult = ''
      try {
        const token = await this.$recaptcha.execute(actionType)
        await this.$axios
          .$get(`/recaptcha-handler`, {
            params: {
              googleToken: token,
            },
          })
          .then((res) => {
            googleResult = JSON.parse(res)
          })
      } catch (e) {
        console.log(e)
      }
      return googleResult
    },
  },
}
